import config from '../config';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const userService = {
  login,
  getBranchOffices
};

function logout() {
  cookies.remove('user');
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        window.location.reload(true);
      }
      const error = (data && (data.message || data.status)) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function login(email, password, branchOfficeId, countryCode) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  };

  return fetch(`${config.apiAuthenticateUrl}`, requestOptions)
    .then(handleResponse)
    .then(user => {
      cookies.set('user', JSON.stringify(user.data.token), {
        domain: config.domain
      });

      cookies.set('email', user.data.email, {
        domain: config.domain
      });

      cookies.set('roles', JSON.stringify(user.data.roles), {
        domain: config.domain
      });

      if (branchOfficeId) {
        cookies.set('branch_office_id', branchOfficeId, {
          domain: config.domain
        });
      }

      if (countryCode) {
        cookies.set('country_code', countryCode, {
          domain: config.domain
        });
      }

      return user;
    });
}

function getBranchOffices(params) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  };
  const url = new URL(`${config.apiGeneralUrl}`);

  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  }

  return fetch(url, requestOptions).then(handleResponse);
};