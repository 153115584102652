import React, { useState, useEffect } from 'react';
import { userService } from './services/user.service';
import {
  Card,
  Form,
  Input,
  Select,
  Button,
  Menu,
  Layout,
  message,
  Row,
  Col
} from 'antd';
import './App.css';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import ReactFlagsSelect from "react-flags-select";
import LogoNaranjo2023 from './images/LogoNaranjo2023.svg';

const { Header, Content } = Layout;
const { Option } = Select;

const textMessage = {
  error: 'Credenciales no validas'
};

function App() {
  const [loading, setLoading] = useState(false);
  const [loggingIn] = useState(true);
  const [branchOffices, setBranchOffices] = useState();
  const [from, setFrom] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [form] = Form.useForm();

  const handleEmail = debounce(async () => {
    setLoading(true)
    if (from && selectedCountry) {
      if (from.includes('sage')) {
        const response = await userService.getBranchOffices({
          byUser: 2,
          email: form.getFieldValue('email'),
          countryCode: selectedCountry
        });
        if (response?.length > 0) {
          setBranchOffices(response);
          form.setFieldsValue({
            branch_office_id: response[0]['id']
          })
        }
      }
    }
    setLoading(false)
  }, 500);

  useEffect(() => {
    handleEmail();
    form.setFieldsValue({
      country: selectedCountry,
    });
  }, [selectedCountry]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    setFrom(query.get('from'));
  }, [])

  const isAllowed = (response, from) => {
    if (from.includes('analytics')) {
      return response.data.roles.includes('super_usuario')
        || response.data.roles.includes('branch_office_manager')
        || response.data.roles.includes('gerencia');
    }

    if (from.includes('auditoria')) {
      return (response.data.roles.includes('super_usuario')
        && response.data.roles.includes('gerencia'))
        || (response.data.roles.includes('super_usuario')
          && response.data.roles.includes('doctor'))
        || (response.data.roles.includes('direccion_medica'));
    }

    if (from.includes('facturacion')) {
      return (response.data.roles.includes('super_usuario')
        && response.data.roles.includes('gerencia'))
        || (response.data.roles.includes('super_usuario')
          || response.data.roles.includes('administracion'));
    }

    if (from.includes('mantenedores')) {
      return (response.data.roles.includes('super_usuario')
        && response.data.roles.includes('gerencia'))
        || (response.data.roles.includes('ejecutivo_comercial')
          || response.data.roles.includes('administracion')
          || response.data.roles.includes('branch_office_manager'))
        || response.data.roles.includes('baterias_peru');
    }

    if (from.includes('sage')) {
      return (
        response.data.roles.includes('super_usuario')
        && response.data.roles.includes('gerencia')
      )
        || (
          response.data.roles.includes('ejecutivo_comercial')
          || response.data.roles.includes('administracion')
          || response.data.roles.includes('branch_office_manager')
          || response.data.roles.includes('doctor')
          || response.data.roles.includes('equipo_medico')
          || response.data.roles.includes('equipo_psicologia')
        );
    }

    if (from.includes('pagos')) {
      return (
        response.data.roles.includes('super_usuario')
        && response.data.roles.includes('gerencia')
      )
        || (response.data.roles.includes('administracion'));
    }
  }

  const handleSubmit = values => {
    setLoading(true);
    userService.login(values['email'], values['password'], values['branch_office_id'], selectedCountry)
      .then(response => {
        if (isAllowed(response, from)) {
          window.location.href = from;
        } else {
          message.error('No tienes permisos suficientes para acceder');
        }
      }).catch(error => {
        message.error(textMessage[error] || error);
      }).finally(() => {
        setLoading(false);
      });
  };


  return (
    <Layout type='flex'>
      <Header>
        <Menu>
          <div className="logo">
            <Row justify="center">
              <Col>
                <img src={LogoNaranjo2023} alt='Logo Naranjo CMT' width={130} />
              </Col>
            </Row>
          </div>
        </Menu>
      </Header>
      <Content style={{ height: '100vh' }} className='pt-90'>
        <Card title="Iniciar Sesión">
          <Form
            form={form}
            layout="vertical"
            size="large"
            onFinish={handleSubmit}
            className='cmt-form'
          >
            <Form.Item
              label="Email"
              name="email"
              onChange={handleEmail}
              className='login-email-input'
            >
              <Input
                className='cmt-input-icon'
                placeholder='Ingresa email'
                prefix={<UserOutlined className="site-form-item-icon" />} 
              />
            </Form.Item>
            <Form.Item label="Contraseña" name="password" className='login-password-input'>
              <Input.Password
                type="password"
                className='cmt-input-icon'
                placeholder='Ingresa contraseña'
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item
              label="País"
              name="country"
              rules={[{
                required: true,
                message: 'País es requerido'
              }]}
              className='login-country-select'
            >
              <ReactFlagsSelect
                placeholder="Selecciona un país"
                countries={["CL", "PE"]}
                selected={selectedCountry}
                onSelect={
                  (code) => setSelectedCountry(code)
                }
              />
            </Form.Item>
            {
              branchOffices?.length > 0 && (
                <Form.Item label="Sucursal" name="branch_office_id" className='login-branch-office-select'>
                  <Select
                    loading={loading}
                    className='cmt-select'
                    placeholder='Selecciona sucursal'
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                  >
                  {
                    branchOffices.map(branchOffice =>
                      <Option key={branchOffice.id} value={branchOffice.id}>
                        {branchOffice.name}
                      </Option>
                    )
                  }
                  </Select>
                </Form.Item>
              )
            }
            <Row justify="center">
              <Col>
                <Form.Item>
                  <Button
                    disabled={from?.includes('sage') && !form.getFieldValue('branch_office_id')}
                    type='primary'
                    htmlType='submit'
                    loading={loading}
                    className={(from?.includes('sage') && !form.getFieldValue('branch_office_id')) ? 'cmt-button disabled' : 'cmt-button primary'}
                    id="login-button"
                  >
                    {loggingIn && <span className="spinner-border spinner-border-sm mr-1" />}
                    Ingresar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Content>
    </Layout>
  );
};

export default App;
